import React, { ChangeEvent, useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import {
  Accordion,
  Box,
  Button,
  Card,
  Checkbox,
  Icon,
  Input,
  Select,
  Skeleton,
  Spinner,
  Tabs,
  Text,
  Title,
  Toggle,
  useToast,
} from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { useTranslation } from 'react-i18next';
import {
  IStoreConfig,
  emptyConfig,
} from '../../lib/interfaces/config.interfaces';
import apiInstance from '../../utils/apiUtils';
import {
  CodeIcon,
  DragIcon,
  DropIcon,
  ExclamationCircleIcon,
  RedoIcon,
  ShoppingCartIcon,
  TextSizeIcon,
} from '@nimbus-ds/icons';
import { AppStatusBar, InputColor } from '../../components';
import ChronometerModels from './ChronometerModels';

const ConfigsPage: React.FC = () => {
  const { t } = useTranslation();

  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(true);
  const [config, setConfig] = useState<IStoreConfig | null>(null);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('general.back')}` });
    getConfig();
    setLoading(false);
  }, []);

  const handleChangeConfig = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;

    const helpConfig: any = { ...config };
    helpConfig[name] =
      type === 'checkbox' ? (event.target as HTMLInputElement).checked : value;
    setConfig({ ...helpConfig });
    return false;
  };

  const getConfig = async () => {
    try {
      const result = await apiInstance.get(`/store/config`);
      const tempConfig = { ...(result.data as IStoreConfig) };

      if (
        tempConfig.time_cart_chronometer &&
        tempConfig.time_cart_chronometer > 0
      ) {
        tempConfig.time_cart_chronometer /= 60;
      }

      tempConfig.css_popup_background_color =
        !tempConfig.css_popup_background_color ||
        tempConfig.css_popup_background_color === ''
          ? '#FFFFFF'
          : tempConfig.css_popup_background_color;
      tempConfig.css_popup_text_color =
        !tempConfig.css_popup_text_color ||
        tempConfig.css_popup_text_color === ''
          ? '#000000'
          : tempConfig.css_popup_text_color;
      tempConfig.css_popup_title_color =
        !tempConfig.css_popup_title_color ||
        tempConfig.css_popup_title_color === ''
          ? '#333333'
          : tempConfig.css_popup_title_color;

      setConfig({ ...emptyConfig, ...tempConfig });
    } catch (error) {
      setConfig({ ...emptyConfig });
    }

    setLoading(false);
  };

  const validateRequiredFields = () => {
    let isValid = true;

    setInvalidFields([...[]]);
    const tempConfig = { ...config } as any;

    if (tempConfig) {
      for (const key in tempConfig) {
        const element = document.getElementById(key) as
          | HTMLInputElement
          | HTMLSelectElement
          | HTMLTextAreaElement;

        if (element && element.required) {
          if (
            (element.type === 'checkbox' && tempConfig[key] !== true) ||
            tempConfig[key] === '' ||
            tempConfig[key] === null
          ) {
            isValid = false;
            invalidFields.push(key);
          }
        }
      }
    }

    setInvalidFields([...invalidFields]);
    return isValid;
  };

  const handleSubmit = async () => {
    if (!validateRequiredFields()) {
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'danger',
        text: `${t('ConfigsPage.submit_danger_fields_empty')}`,
      });
      return false;
    }
    try {
      const data = { ...config };
      delete data._id;
      delete data.createdAt;
      delete data.updatedAt;
      await apiInstance.post(`/store/config`, data);
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'success',
        text: `${t('ConfigsPage.submit_success')}`,
      });
    } catch (error) {
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'danger',
        text: `${t('ConfigsPage.submit_danger')}`,
      });
    }
  };

  const handleSyncProducts = async () => {
    try {
      const result = await apiInstance.post(`/reserva-estoque/sync-all`);
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'success',
        text: `${t('ConfigsPage.tabs.sync_products.notification.success')}`,
      });
    } catch (error) {
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'danger',
        text: `${t('ConfigsPage.tabs.sync_products.notification.error')}`,
      });
    }
  };

  return (
    <>
      <Page maxWidth="800px">
        <Page.Header
          title={t('ConfigsPage.title')}
          buttonStack={<AppStatusBar type="toggle" />}
        />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <Box display="grid" gap="6">
                <Tabs preSelectedTab={0}>
                  <Tabs.Item label={t('ConfigsPage.tabs.general.title')}>
                    <Box display="grid" gap="6">
                      <Box display="grid" gap="6">
                        <Card>
                          <Card.Header
                            title={`${t(
                              'ConfigsPage.tabs.general.form.cards.cart_chronometer_expirations.title',
                            )}`}
                          />
                          <Card.Body>
                            <Box display="grid" gap="6">
                              <Box display="grid" gap="1">
                                <Text>
                                  {t(
                                    'ConfigsPage.tabs.general.form.cards.cart_chronometer.description_line1',
                                  )}
                                </Text>
                                <Text>
                                  {t(
                                    'ConfigsPage.tabs.general.form.cards.cart_chronometer_expirations.description',
                                  )}
                                </Text>
                              </Box>

                              <Box display="grid" gap="2">
                                <Title as="h3" fontSize="4-5">
                                  {t(
                                    'ConfigsPage.tabs.general.form.cards.cart_chronometer_expirations.inputs.time_cart_chronometer.title',
                                  )}
                                </Title>
                                <FormField
                                  label={t(
                                    'ConfigsPage.tabs.general.form.cards.cart_chronometer_expirations.inputs.time_cart_chronometer.label',
                                  )}
                                >
                                  <Box maxWidth={{ xs: '100%', md: '200px' }}>
                                    <Input
                                      id="time_cart_chronometer"
                                      name="time_cart_chronometer"
                                      type="number"
                                      disabled={loading}
                                      onChange={handleChangeConfig}
                                      min="1"
                                      step="1"
                                      value={
                                        config
                                          ? config.time_cart_chronometer
                                          : 20
                                      }
                                      required={true}
                                      appearance={
                                        invalidFields.includes(
                                          'time_cart_chronometer',
                                        )
                                          ? 'danger'
                                          : 'neutral'
                                      }
                                      append={
                                        <Text>
                                          {t(
                                            'ConfigsPage.tabs.general.form.cards.cart_chronometer_expirations.inputs.time_cart_chronometer.append_text',
                                          )}
                                        </Text>
                                      }
                                      appendPosition="end"
                                    />
                                  </Box>
                                </FormField>
                              </Box>

                              <Box display="grid" gap="2">
                                <Checkbox
                                  name="is_chronometer_restart"
                                  label={`${t(
                                    'ConfigsPage.tabs.general.form.cards.cart_chronometer_expirations.inputs.is_chronometer_restart.label',
                                  )}`}
                                  disabled={loading}
                                  checked={
                                    config?.is_chronometer_restart || false
                                  }
                                  onChange={handleChangeConfig}
                                />
                              </Box>

                              <Box display="grid" gap="2">
                                <Checkbox
                                  name="is_pause_timer_checkout"
                                  disabled={loading}
                                  label={`${t(
                                    'ConfigsPage.tabs.general.form.cards.cart_chronometer_expirations.inputs.is_pause_timer_checkout.label',
                                  )}`}
                                  checked={config?.is_pause_timer_checkout}
                                  onChange={handleChangeConfig}
                                />
                              </Box>
                            </Box>
                          </Card.Body>
                        </Card>
                        <Card>
                          <Card.Header>
                            <Box display="flex" justifyContent="space-between">
                              <Title as="h3">
                                {t(
                                  'ConfigsPage.tabs.general.form.cards.cart_chronometer.title',
                                )}
                              </Title>

                              <Toggle
                                name="is_cart_chronometer"
                                disabled={loading || config === null}
                                active={config?.is_cart_chronometer}
                                onChange={handleChangeConfig}
                              />
                            </Box>
                          </Card.Header>
                        </Card>
                      </Box>
                    </Box>
                  </Tabs.Item>
                  <Tabs.Item label={t('ConfigsPage.tabs.style.title')}>
                    <Card>
                      <Card.Body>
                        <Box
                          display="flex"
                          alignItems="flex-start"
                          gap="6"
                          boxSizing="border-box"
                          flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                        >
                          <Box
                            width={{ xs: '100%', md: '35%' }}
                            boxSizing="border-box"
                          >
                            <Accordion>
                              {/* MODELO DO CRONOMETRO */}
                              <Accordion.Item index="0">
                                <Accordion.Header
                                  icon={<CodeIcon size={18} />}
                                  title={`${t(
                                    'ConfigsPage.tabs.style.accordion.type.title',
                                  )}`}
                                />
                                <Accordion.Body>
                                  <Box width="100%" display="grid" gap="2">
                                    <FormField
                                      label={`${t(
                                        'ConfigsPage.tabs.style.accordion.type.form.inputs.chronometer_type.label',
                                      )}`}
                                    >
                                      <Select
                                        id="chronometer_type"
                                        name="chronometer_type"
                                        onChange={handleChangeConfig}
                                        value={config?.chronometer_type}
                                        disabled={loading}
                                        required={true}
                                        appearance={
                                          invalidFields.includes(
                                            'chronometer_type',
                                          )
                                            ? 'danger'
                                            : 'neutral'
                                        }
                                      >
                                        <Select.Option
                                          value="model_1"
                                          label={t(
                                            'ConfigsPage.tabs.style.accordion.type.form.inputs.chronometer_type.option1_label',
                                          )}
                                        />
                                        <Select.Option
                                          value="model_2"
                                          label={t(
                                            'ConfigsPage.tabs.style.accordion.type.form.inputs.chronometer_type.option2_label',
                                          )}
                                        />
                                        <Select.Option
                                          value="model_3"
                                          label={t(
                                            'ConfigsPage.tabs.style.accordion.type.form.inputs.chronometer_type.option3_label',
                                          )}
                                        />
                                      </Select>
                                    </FormField>
                                  </Box>
                                </Accordion.Body>
                              </Accordion.Item>
                              {/* POSICIONAMENTO */}
                              <Accordion.Item index="1">
                                <Accordion.Header
                                  borderBottom="base"
                                  icon={<DragIcon size={18} />}
                                  title={`${t(
                                    'ConfigsPage.tabs.style.accordion.position.title',
                                  )}`}
                                />
                                <Accordion.Body borderBottom="base">
                                  <Box width="100%" display="grid" gap="2">
                                    <FormField
                                      label={`${t(
                                        'ConfigsPage.tabs.style.accordion.position.form.inputs.chronometer_position.label',
                                      )}`}
                                    >
                                      <Select
                                        id="chronometer_position_select"
                                        name="chronometer_position"
                                        onChange={handleChangeConfig}
                                        value={config?.chronometer_position}
                                        disabled={loading}
                                        required={true}
                                        appearance={
                                          invalidFields.includes(
                                            'chronometer_position',
                                          )
                                            ? 'danger'
                                            : 'neutral'
                                        }
                                      >
                                        <Select.Option
                                          value="cart_start"
                                          label={t(
                                            'ConfigsPage.tabs.style.accordion.position.form.inputs.chronometer_position.option1_label',
                                          )}
                                        />
                                        <Select.Option
                                          value="cart_end"
                                          label={t(
                                            'ConfigsPage.tabs.style.accordion.position.form.inputs.chronometer_position.option2_label',
                                          )}
                                        />
                                        <Select.Option
                                          value="custom"
                                          label={t(
                                            'ConfigsPage.tabs.style.accordion.position.form.inputs.chronometer_position.option3_label',
                                          )}
                                        />
                                      </Select>
                                    </FormField>
                                    {config &&
                                    config.chronometer_position === 'custom' ? (
                                      <Box
                                        display="grid"
                                        gap="4"
                                        marginTop="2"
                                        paddingTop="2"
                                        borderWidth="none"
                                        borderTopWidth="1"
                                        borderColor="neutral-interactive"
                                        borderStyle="solid"
                                      >
                                        <FormField
                                          label={t(
                                            'ConfigsPage.tabs.style.accordion.position.form.inputs.chronometer_position_custom.label',
                                          )}
                                          helpText={`${t(
                                            'ConfigsPage.tabs.style.accordion.position.form.inputs.chronometer_position_custom.helper',
                                          )}`}
                                          showHelpText={true}
                                          helpIcon={ExclamationCircleIcon}
                                        >
                                          <Input
                                            id="chronometer_position_custom"
                                            name="chronometer_position_custom"
                                            onChange={handleChangeConfig}
                                            value={
                                              config?.chronometer_position_custom
                                            }
                                            placeholder="#element-id"
                                          />
                                        </FormField>
                                        <FormField
                                          label={t(
                                            'ConfigsPage.tabs.style.accordion.position.form.inputs.chronometer_position_custom_type.label',
                                          )}
                                          helpText={`${t(
                                            'ConfigsPage.tabs.style.accordion.position.form.inputs.chronometer_position_custom_type.helper',
                                          )}`}
                                          showHelpText={true}
                                          helpIcon={ExclamationCircleIcon}
                                        >
                                          <Select
                                            id="chronometer_position_custom_type"
                                            name="chronometer_position_custom_type"
                                            onChange={handleChangeConfig}
                                            value={
                                              config?.chronometer_position_custom_type
                                            }
                                          >
                                            <Select.Option
                                              value="before"
                                              label={`${t(
                                                'ConfigsPage.tabs.style.accordion.position.form.inputs.chronometer_position_custom_type.option_before',
                                              )}`}
                                            />
                                            <Select.Option
                                              value="prepend"
                                              label={`${t(
                                                'ConfigsPage.tabs.style.accordion.position.form.inputs.chronometer_position_custom_type.option_prepend',
                                              )}`}
                                            />
                                            <Select.Option
                                              value="append"
                                              label={`${t(
                                                'ConfigsPage.tabs.style.accordion.position.form.inputs.chronometer_position_custom_type.option_append',
                                              )}`}
                                            />
                                            <Select.Option
                                              value="after"
                                              label={`${t(
                                                'ConfigsPage.tabs.style.accordion.position.form.inputs.chronometer_position_custom_type.option_after',
                                              )}`}
                                            />
                                            <Select.Option
                                              value="into"
                                              label={`${t(
                                                'ConfigsPage.tabs.style.accordion.position.form.inputs.chronometer_position_custom_type.option_into',
                                              )}`}
                                            />
                                          </Select>
                                        </FormField>
                                      </Box>
                                    ) : (
                                      <></>
                                    )}
                                  </Box>
                                </Accordion.Body>
                              </Accordion.Item>
                              {/* TEXTOS */}
                              <Accordion.Item index="2">
                                <Accordion.Header
                                  borderBottom="base"
                                  icon={<TextSizeIcon size={18} />}
                                  title={`${t(
                                    'ConfigsPage.tabs.style.accordion.text.title',
                                  )}`}
                                />
                                <Accordion.Body borderBottom="base">
                                  <Box width="100%" display="grid" gap="2">
                                    <FormField
                                      label={`${t(
                                        'ConfigsPage.tabs.style.accordion.text.form.inputs.chronometer_title.label',
                                      )}`}
                                    >
                                      <Input
                                        id="chronometer_title"
                                        name="chronometer_title"
                                        onChange={handleChangeConfig}
                                        required={true}
                                        appearance={
                                          invalidFields.includes(
                                            'chronometer_title',
                                          )
                                            ? 'danger'
                                            : 'neutral'
                                        }
                                        disabled={loading || !config}
                                        value={config?.chronometer_title}
                                      />
                                    </FormField>
                                    <FormField
                                      label={`${t(
                                        'ConfigsPage.tabs.style.accordion.text.form.inputs.chronometer_text.label',
                                      )}`}
                                    >
                                      <Input
                                        id="chronometer_text"
                                        name="chronometer_text"
                                        onChange={handleChangeConfig}
                                        placeholder="Ex: Seu carrinho irá expirar em {value} min"
                                        required={true}
                                        appearance={
                                          invalidFields.includes(
                                            'chronometer_text',
                                          )
                                            ? 'danger'
                                            : 'neutral'
                                        }
                                        disabled={loading || !config}
                                        value={config?.chronometer_text}
                                      />
                                    </FormField>
                                    <FormField
                                      label={`${t(
                                        'ConfigsPage.tabs.style.accordion.text.form.inputs.chronometer_icon.label',
                                      )}`}
                                    >
                                      <Input
                                        name="chronometer_icon"
                                        onChange={handleChangeConfig}
                                        required={false}
                                        disabled={loading || !config}
                                        value={config?.chronometer_icon}
                                      />
                                    </FormField>
                                  </Box>
                                </Accordion.Body>
                              </Accordion.Item>
                              {/* CTA DE CHECKOUT */}
                              <Accordion.Item index="3">
                                <Accordion.Header
                                  borderBottom="base"
                                  icon={<ShoppingCartIcon size={18} />}
                                  title={`${t(
                                    'ConfigsPage.tabs.style.accordion.checkout_cta.title',
                                  )}`}
                                />
                                <Accordion.Body borderBottom="base">
                                  <Box width="100%" display="grid" gap="2">
                                    <FormField
                                      label={`${t(
                                        'ConfigsPage.tabs.style.accordion.checkout_cta.form.inputs.chronometer_checkout_button.label',
                                      )}`}
                                    >
                                      <Input
                                        id="chronometer_checkout_button"
                                        name="chronometer_checkout_button"
                                        onChange={handleChangeConfig}
                                        placeholder="Finalizar compra"
                                        appearance={
                                          invalidFields.includes(
                                            'chronometer_checkout_button',
                                          )
                                            ? 'danger'
                                            : 'neutral'
                                        }
                                        required={true}
                                        disabled={loading || !config}
                                        value={
                                          config?.chronometer_checkout_button
                                        }
                                      />
                                    </FormField>
                                  </Box>
                                </Accordion.Body>
                              </Accordion.Item>
                              {/* CORES */}
                              <Accordion.Item index="4">
                                <Accordion.Header
                                  borderBottom="base"
                                  icon={<DropIcon size={18} />}
                                  title={`${t(
                                    'ConfigsPage.tabs.style.accordion.colors.title',
                                  )}`}
                                />
                                <Accordion.Body borderBottom="base">
                                  <Box width="100%" display="grid" gap="6">
                                    <Title as="h5">{`${t(
                                      'ConfigsPage.tabs.style.accordion.colors.form.legends.default',
                                    )}`}</Title>
                                    <InputColor
                                      label={`${t(
                                        'ConfigsPage.tabs.style.accordion.colors.form.inputs.css_primary_color.label',
                                      )}`}
                                      name="css_primary_color"
                                      onChange={handleChangeConfig}
                                      required={true}
                                      disabled={loading || !config}
                                      value={config?.css_primary_color}
                                    />
                                    <InputColor
                                      label={`${t(
                                        'ConfigsPage.tabs.style.accordion.colors.form.inputs.css_secondary_color.label',
                                      )}`}
                                      name="css_secondary_color"
                                      onChange={handleChangeConfig}
                                      required={true}
                                      disabled={loading || !config}
                                      value={config?.css_secondary_color}
                                    />
                                    <hr />
                                    <Title as="h5">{`${t(
                                      'ConfigsPage.tabs.style.accordion.colors.form.legends.chronometer',
                                    )}`}</Title>
                                    <InputColor
                                      label={`${t(
                                        'ConfigsPage.tabs.style.accordion.colors.form.inputs.css_chronometer_background_color.label',
                                      )}`}
                                      name="css_chronometer_background_color"
                                      onChange={handleChangeConfig}
                                      required={true}
                                      disabled={loading || !config}
                                      value={
                                        config?.css_chronometer_background_color
                                      }
                                    />
                                    <InputColor
                                      label={`${t(
                                        'ConfigsPage.tabs.style.accordion.colors.form.inputs.css_chronometer_text_color.label',
                                      )}`}
                                      name="css_chronometer_text_color"
                                      onChange={handleChangeConfig}
                                      required={true}
                                      disabled={loading || !config}
                                      value={config?.css_chronometer_text_color}
                                    />
                                    <InputColor
                                      label={`${t(
                                        'ConfigsPage.tabs.style.accordion.colors.form.inputs.css_cta_background_color.label',
                                      )}`}
                                      name="css_cta_background_color"
                                      onChange={handleChangeConfig}
                                      required={true}
                                      disabled={loading || !config}
                                      value={config?.css_cta_background_color}
                                    />
                                    <InputColor
                                      label={`${t(
                                        'ConfigsPage.tabs.style.accordion.colors.form.inputs.css_cta_text_color.label',
                                      )}`}
                                      name="css_cta_text_color"
                                      onChange={handleChangeConfig}
                                      required={true}
                                      disabled={loading || !config}
                                      value={config?.css_cta_text_color}
                                    />
                                    <hr />
                                    <Title as="h5">{`${t(
                                      'ConfigsPage.tabs.style.accordion.colors.form.legends.popup',
                                    )}`}</Title>
                                    <InputColor
                                      label={`${t(
                                        'ConfigsPage.tabs.style.accordion.colors.form.inputs.css_popup_background_color.label',
                                      )}`}
                                      name="css_popup_background_color"
                                      onChange={handleChangeConfig}
                                      required={true}
                                      disabled={loading || !config}
                                      value={config?.css_popup_background_color}
                                    />
                                    <InputColor
                                      label={`${t(
                                        'ConfigsPage.tabs.style.accordion.colors.form.inputs.css_popup_title_color.label',
                                      )}`}
                                      name="css_popup_title_color"
                                      onChange={handleChangeConfig}
                                      required={true}
                                      disabled={loading || !config}
                                      value={config?.css_popup_title_color}
                                    />
                                    <InputColor
                                      label={`${t(
                                        'ConfigsPage.tabs.style.accordion.colors.form.inputs.css_popup_text_color.label',
                                      )}`}
                                      name="css_popup_text_color"
                                      onChange={handleChangeConfig}
                                      required={true}
                                      disabled={loading || !config}
                                      value={config?.css_popup_text_color}
                                    />
                                  </Box>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Box>
                          <Box
                            position="sticky"
                            top="15px"
                            width={{ xs: '100%', md: '65%' }}
                            boxSizing="border-box"
                          >
                            <Box display="grid" gap="1">
                              <Title as="h3">
                                {t('ConfigsPage.tabs.style.preview.title')}
                              </Title>
                              {loading ? (
                                <Skeleton height="60px" width="100%" />
                              ) : (
                                <ChronometerModels config={config} />
                              )}
                              <Text>
                                {t(
                                  'ConfigsPage.tabs.style.preview.description',
                                )}
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                      </Card.Body>
                    </Card>
                  </Tabs.Item>
                  <Tabs.Item label={t('ConfigsPage.tabs.notifications.title')}>
                    <Box display="grid" gap="6">
                      <Card>
                        <Card.Header>
                          <Box display="flex" justifyContent="space-between">
                            <Title as="h3">
                              {t(
                                'ConfigsPage.tabs.notifications.form.cards.first_product_notification.title',
                              )}
                            </Title>

                            <Toggle
                              name="is_first_product_notification"
                              disabled={loading || config === null}
                              active={config?.is_first_product_notification}
                              onChange={handleChangeConfig}
                            />
                          </Box>
                        </Card.Header>
                        <Card.Body>
                          <Box display="grid" gap="6">
                            <FormField
                              label={t(
                                'ConfigsPage.tabs.notifications.form.cards.first_product_notification.input_label',
                              )}
                              helpText={`${t(
                                'ConfigsPage.tabs.notifications.form.cards.first_product_notification.input_help_text',
                              )}`}
                              showHelpText={true}
                              helpIcon={ExclamationCircleIcon}
                            >
                              <Input
                                id="first_product_notification_message"
                                name="first_product_notification_message"
                                onChange={handleChangeConfig}
                                required={true}
                                disabled={loading}
                                appearance={
                                  invalidFields.includes(
                                    'first_product_notification_message',
                                  )
                                    ? 'danger'
                                    : 'neutral'
                                }
                                placeholder={`${t(
                                  'ConfigsPage.tabs.notifications.form.cards.first_product_notification.input_placeholder',
                                )}`}
                                value={
                                  config?.first_product_notification_message
                                }
                              />
                            </FormField>
                          </Box>
                        </Card.Body>
                      </Card>

                      <Card>
                        <Card.Header>
                          <Box display="flex" justifyContent="space-between">
                            <Title as="h3">
                              {t(
                                'ConfigsPage.tabs.notifications.form.cards.two_minutes_notification.title',
                              )}
                            </Title>

                            <Toggle
                              name="is_two_minutes_notification"
                              disabled={loading || config === null}
                              active={config?.is_two_minutes_notification}
                              onChange={handleChangeConfig}
                            />
                          </Box>
                        </Card.Header>
                        <Card.Body>
                          <Box display="grid" gap="6">
                            <FormField
                              label={t(
                                'ConfigsPage.tabs.notifications.form.cards.two_minutes_notification.input_label',
                              )}
                            >
                              <Input
                                id="two_minutes_notification_message"
                                name="two_minutes_notification_message"
                                onChange={handleChangeConfig}
                                disabled={loading}
                                appearance={
                                  invalidFields.includes(
                                    'two_minutes_notification_message',
                                  )
                                    ? 'danger'
                                    : 'neutral'
                                }
                                required={true}
                                placeholder={`${t(
                                  'ConfigsPage.tabs.notifications.form.cards.two_minutes_notification.input_placeholder',
                                )}`}
                                value={config?.two_minutes_notification_message}
                              />
                            </FormField>
                          </Box>
                        </Card.Body>
                      </Card>

                      <Card>
                        <Card.Header>
                          <Box display="flex" justifyContent="space-between">
                            <Title as="h3">
                              {t(
                                'ConfigsPage.tabs.notifications.form.cards.expired_popup.title',
                              )}
                            </Title>
                          </Box>
                        </Card.Header>
                        <Card.Body>
                          <Box display="grid" gap="6">
                            <FormField
                              label={t(
                                'ConfigsPage.tabs.notifications.form.cards.expired_popup.input_title_label',
                              )}
                            >
                              <Input
                                id="popup_alert_expired_cart_title"
                                name="popup_alert_expired_cart_title"
                                onChange={handleChangeConfig}
                                disabled={loading}
                                appearance={
                                  invalidFields.includes(
                                    'popup_alert_expired_cart_title',
                                  )
                                    ? 'danger'
                                    : 'neutral'
                                }
                                required={true}
                                placeholder={`${t(
                                  'ConfigsPage.tabs.notifications.form.cards.expired_popup.input_title_placeholder',
                                )}`}
                                value={config?.popup_alert_expired_cart_title}
                              />
                            </FormField>
                            <FormField
                              label={t(
                                'ConfigsPage.tabs.notifications.form.cards.expired_popup.input_message_label',
                              )}
                            >
                              <Input
                                id="popup_alert_expired_cart_message"
                                name="popup_alert_expired_cart_message"
                                onChange={handleChangeConfig}
                                disabled={loading}
                                appearance={
                                  invalidFields.includes(
                                    'popup_alert_expired_cart_message',
                                  )
                                    ? 'danger'
                                    : 'neutral'
                                }
                                required={true}
                                placeholder={`${t(
                                  'ConfigsPage.tabs.notifications.form.cards.expired_popup.input_message_placeholder',
                                )}`}
                                value={config?.popup_alert_expired_cart_message}
                              />
                            </FormField>
                            <FormField
                              label={t(
                                'ConfigsPage.tabs.notifications.form.cards.expired_popup.input_cta_text_label',
                              )}
                            >
                              <Input
                                id="popup_alert_expired_cart_cta_text"
                                name="popup_alert_expired_cart_cta_text"
                                onChange={handleChangeConfig}
                                disabled={loading}
                                appearance={
                                  invalidFields.includes(
                                    'popup_alert_expired_cart_cta_text',
                                  )
                                    ? 'danger'
                                    : 'neutral'
                                }
                                required={true}
                                placeholder={`${t(
                                  'ConfigsPage.tabs.notifications.form.cards.expired_popup.input_cta_text_placeholder',
                                )}`}
                                value={
                                  config?.popup_alert_expired_cart_cta_text
                                }
                              />
                            </FormField>
                          </Box>
                        </Card.Body>
                      </Card>
                    </Box>
                  </Tabs.Item>
                  <Tabs.Item label={t('ConfigsPage.tabs.sync_products.title')}>
                    <Box display="grid" gap="6">
                      <Card>
                        <Card.Body>
                          <Box display="grid" gap="6">
                            <Box display="grid" gap="4">
                              <Text>
                                {t(
                                  'ConfigsPage.tabs.sync_products.card.description',
                                )}
                              </Text>
                            </Box>

                            <Button
                              onClick={handleSyncProducts}
                              appearance="neutral"
                              disabled={loading}
                            >
                              <Icon
                                color="currentColor"
                                source={<RedoIcon />}
                              />
                              {t(
                                'ConfigsPage.tabs.sync_products.card.cta_text',
                              )}
                            </Button>
                          </Box>
                        </Card.Body>
                      </Card>
                    </Box>
                  </Tabs.Item>
                </Tabs>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    type="submit"
                    disabled={loading}
                    appearance="primary"
                    onClick={handleSubmit}
                  >
                    {loading ? (
                      <Spinner color="currentColor" size="small" />
                    ) : (
                      ''
                    )}
                    {t('general.save')}
                  </Button>
                </Box>
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
};

export default ConfigsPage;
