import React, { useEffect, useState } from 'react';

import { t } from 'i18next';
import {
  Alert,
  Box,
  Button,
  Spinner,
  Toggle,
  useToast,
} from '@nimbus-ds/components';
import apiInstance from '../../utils/apiUtils';

const AppStatusBar: React.FC<{ type?: 'alert' | 'toggle' }> = ({
  type = 'alert',
}) => {
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [loadingActive, setLoadingActive] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    getStatus();
  }, []);

  const getStatus = async () => {
    try {
      const result = await apiInstance.get(`/reserva-estoque/status`);
      const data = { ...(result.data as { is_active: boolean }) };

      setActive(data.is_active);
    } catch (error) {
      setActive(false);
    }
    setLoading(false);
  };

  const startStopApp = async () => {
    try {
      setActive(!active);
      setLoadingActive(true);
      const result = await apiInstance.post(
        `/reserva-estoque/${active ? 'stop' : 'start'}`,
      );

      if (result.status !== 200) {
        addToast({
          id: 'stats' + new Date().toISOString(),
          type: 'danger',
          text: `${t(
            `components.AppStatusBar.toasts.${
              !active ? 'active' : 'desactive'
            }_status_error`,
          )}`,
        });
        setActive(!active);
      } else {
        addToast({
          id: 'stats' + new Date().toISOString(),
          type: 'success',
          text: `${t(
            `components.AppStatusBar.toasts.${
              !active ? 'active' : 'desactive'
            }_status_success`,
          )}`,
        });
      }
    } catch (error) {
      console.log(error);

      addToast({
        id: 'stats' + new Date().toISOString(),
        type: 'danger',
        text: `${t(
          `components.AppStatusBar.toasts.${
            !active ? 'active' : 'desactive'
          }_status_error`,
        )}`,
      });
    }
    setLoadingActive(false);
  };

  if (loading || (type === 'alert' && active)) {
    return <></>;
  }

  if (type === 'alert') {
    return (
      <Box marginBottom="5">
        <Alert
          appearance="danger"
          title={`${t('components.AppStatusBar.alert.danger.title')}`}
        >
          {`${t('components.AppStatusBar.alert.danger.message')}`}
          <br />
          {`${t('components.AppStatusBar.alert.danger.message_2')}`}
          <Box display="flex" flexWrap="wrap" gap="2">
            <Button
              disabled={loadingActive}
              onClick={startStopApp}
              appearance="neutral"
            >
              {loadingActive ? (
                <>
                  <Spinner color="currentColor" size="small" /> Ativando app
                </>
              ) : (
                `${t('components.AppStatusBar.alert.danger.cta_text')}`
              )}
            </Button>
          </Box>
        </Alert>
      </Box>
    );
  } else {
    return (
      <Toggle
        disabled={loadingActive}
        name="app-status"
        checked={active}
        onClick={startStopApp}
        label={`${t('components.ToggleAppStatus.label')}`}
      />
    );
  }
};

export default AppStatusBar;
