export const formatMoney = (value: number | string): string => {
  try {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    let currency = 'BRL';
    let lang = 'pt';

    try {
      const storeInfo = localStorage.getItem('store_info') || '{}';
      const storeInfoJson = JSON.parse(storeInfo);
      if ('currency' in storeInfoJson && 'language' in storeInfoJson) {
        currency = storeInfoJson.currency;
        lang = storeInfoJson.language;
      }
    } catch (error) {
      console.error(error);
    }

    const money = value.toLocaleString(lang, { style: 'currency', currency });
    return money;
  } catch (error) {
    console.error(error);
    return `${value}`;
  }
};

export const formatSimpleDate = (
  date: string | Date,
  format = 'dd-mm-yyyy',
): string => {
  try {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    console.log(year, month, day);

    return format
      .replaceAll('yyyy', `${year}`)
      .replaceAll('mm', month)
      .replaceAll('dd', day);
  } catch (error) {
    return typeof date === 'string' ? date : date.toISOString();
  }
};

export const formatDate = (
  date: string | Date,
  monthType: 'long' | 'numeric' = 'numeric',
): string => {
  try {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    const options: any = {
      year: 'numeric',
      month: monthType,
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    return date.toLocaleString(navigator.language || 'pt', options);
  } catch (error) {
    return '';
  }
};
