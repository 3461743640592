import axios from 'axios';
import {
  getSessionToken,
  getStoreInfo,
  navigateExit,
} from '@tiendanube/nexo/helpers';
import nexo from '../nexoClient';

const apiMDInstance = axios.create({
  baseURL: `${process.env.REACT_APP_MIDDLEWARE_API_URI}`,
});

apiMDInstance.interceptors.request.use(async (request) => {
  const token = await getSessionToken(nexo);
  const store = await getStoreInfo(nexo);
  const bearerToken = `Bearer ${token}`;
  request.headers = {
    ...request.headers,
    Authorization: bearerToken,
    'Store-Country': `${store.country}`,
    'app-slug': `${process.env.REACT_APP_NAME}`.toLowerCase(),
  } as any;
  return request;
});

apiMDInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      navigateExit(nexo);
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

export default apiMDInstance;
