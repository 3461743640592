import React from 'react';
import { Box, Button, Icon } from '@nimbus-ds/components';
import { useHistory } from 'react-router-dom';
import { LockIcon } from '@nimbus-ds/icons';
import { t } from 'i18next';

const BillingButton: React.FC = () => {
  const { push } = useHistory();
  const handleGoToBilling = () => push('/billing');

  return (
    <Button onClick={handleGoToBilling} appearance="neutral">
      <Box as="span" display="flex" gap="2">
        <Icon source={<LockIcon />} color="currentColor" />
        {t('BillingPage.goToBilling')}
      </Box>
    </Button>
  );
};

export default BillingButton;
