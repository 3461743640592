// ProtectedRoute.tsx
import React, { useState, useEffect, ComponentType } from 'react';
import { Route, Redirect, RouteProps, useHistory } from 'react-router-dom';
import apiMDInstance from './utils/apiMiddlewareUtils';

interface ProtectedRouteProps extends RouteProps {
  component: ComponentType<any>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const [permission, setPermission] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    apiMDInstance('/billing/check-payment')
      .then(({ data, status }) => {
        if (status === 200 && data) {
          if (
            data.app_status === true &&
            data.stripe_customer_id !== '' &&
            data.stripe_subscription_id !== ''
          ) {
            setPermission(true);
            setLoading(false);
            return true;
          }
        }
        setPermission(false);
        setLoading(false);
      })
      .catch(() => {
        setPermission(false);
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading permissions...</div>;

  return (
    <Route
      {...rest}
      render={(props) =>
        permission ? <Component {...props} /> : <Redirect to="/billing" />
      }
    />
  );
};

export default ProtectedRoute;
