import { connect, getStoreInfo, iAmReady } from '@tiendanube/nexo/helpers';
import { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import nexo from './nexoClient';
import NexoSyncRoute from './NexoSyncRoute';
import { BillingPage, ConfigsPage, MainPage, UnauthorizedPage } from './pages';
import { DarkModeProvider, BaseLayout } from './components';

import { ToastProvider } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import ProtectedRoute from './ProtectedRoute';

function App() {
  const [isConnect, setIsConnect] = useState(false);

  const { i18n } = useTranslation();

  useEffect(() => {
    connect(nexo).then(async () => {
      setIsConnect(true);
      iAmReady(nexo);

      const store = await getStoreInfo(nexo);
      i18n.changeLanguage(store.language);
    });
  }, []);

  if (!isConnect) return <div>connecting..</div>;

  return (
    <DarkModeProvider>
      <BrowserRouter>
        <ToastProvider>
          <BaseLayout>
            <NexoSyncRoute>
              <Switch>
                <ProtectedRoute path="/" exact component={MainPage} />
                <ProtectedRoute path="/configs" component={ConfigsPage} />
                <Route path="/billing" component={BillingPage}></Route>
                <Route
                  path="/unauthorized"
                  component={UnauthorizedPage}
                ></Route>
              </Switch>
            </NexoSyncRoute>
          </BaseLayout>
        </ToastProvider>
      </BrowserRouter>
    </DarkModeProvider>
  );
}

export default App;
